:root {
  --color-portfolio-header-label: theme(colors.neutral.600);
  --color-portfolio-header-title: theme(colors.black);

  --color-portfolio-header-show-more: theme(colors.primary.400);

  --color-portfolio-header-card-title: theme(colors.black);

  .dark-theme {
    --color-portfolio-header-label: theme(colors.secondary.500);
    --color-portfolio-header-title: theme(colors.secondary.100);

    --color-portfolio-header-card-title: theme(colors.secondary.100);
  }

  .premium-theme {
    --color-portfolio-header-show-more: theme(colors.premium.400);
  }
}
