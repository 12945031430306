:root {
  --color-content-intro-text: theme(colors.neutral.700);
  --color-content-footer-text: theme(colors.neutral.800);
  --color-content-footer-text-contrast: theme(colors.neutral.700);
  --color-content-footer-link: theme(colors.neutral.700);

  .dark-theme {
    --color-content-intro-text: theme(colors.secondary.300);
    --color-content-footer-text: theme(colors.secondary.400);
    --color-content-footer-text-contrast: theme(colors.secondary.400);
    --color-content-footer-link: theme(colors.secondary.300);
  }
}
