:root {
  --color-fund-detail-background: theme(colors.neutral.100);

  --color-fund-detail-header-label: theme(colors.neutral.600);
  --color-fund-detail-header-title: theme(colors.black);
  --color-fund-detail-header-description: theme(colors.neutral.700);

  --color-fund-detail-show-more: theme(colors.primary.400);

  --color-fund-detail-download: theme(colors.neutral.600);

  --color-fund-detail-rating-low: theme(colors.warning.300);
  --color-fund-detail-rating-high: theme(colors.primary.400);

  --color-fund-detail-performance-label: theme(colors.neutral.700);
  --color-fund-detail-performance-value: theme(colors.black);
  --color-fund-detail-performance-border: theme(colors.neutral.100);

  --color-fund-feature-label: theme(colors.neutral.800);
  --color-fund-feature-value: theme(colors.black);

  --color-fund-position-title: theme(colors.black);
  --color-fund-position-value: theme(colors.black);
  --color-fund-position-sector: theme(colors.neutral.700);
  --color-fund-position-border: theme(colors.neutral.200);

  --color-fund-transaction-group-heading: theme(colors.neutral.700);

  --color-funds-position-label: theme(colors.black);
  --color-funds-position-value: theme(colors.black);

  --color-funds-position-sub-label: theme(colors.neutral.700);
  --color-funds-position-sub-value: theme(colors.neutral.700);

  --color-fund-chart-header-value-positive: theme(colors.success.400);
  --color-fund-chart-header-value-negative: theme(colors.danger.400);

  .dark-theme {
    --color-fund-detail-background: theme(colors.secondary.1000);

    --color-fund-detail-header-label: theme(colors.secondary.500);
    --color-fund-detail-header-title: theme(colors.secondary.100);
    --color-fund-detail-header-description: theme(colors.secondary.200);

    --color-fund-detail-download: theme(colors.secondary.600);

    --color-fund-detail-performance-label: theme(colors.secondary.200);
    --color-fund-detail-performance-value: theme(colors.secondary.100);
    --color-fund-detail-performance-border: theme(colors.secondary.1000);

    --color-fund-feature-label: theme(colors.secondary.200);
    --color-fund-feature-value: theme(colors.secondary.100);

    --color-fund-position-title: theme(colors.secondary.100);
    --color-fund-position-value: theme(colors.secondary.100);
    --color-fund-position-sector: theme(colors.secondary.500);
    --color-fund-position-border: theme(colors.secondary.1000);

    --color-fund-transaction-group-heading: theme(colors.secondary.500);

    --color-funds-position-label: theme(colors.secondary.100);
    --color-funds-position-value: theme(colors.secondary.100);

    --color-funds-position-sub-label: theme(colors.secondary.500);
    --color-funds-position-sub-value: theme(colors.secondary.500);
  }

  .premium-theme {
    --color-fund-detail-show-more: theme(colors.premium.400);

    --color-fund-detail-rating-high: theme(colors.premium.400);
  }
}
