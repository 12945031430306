:root {
  --color-card-button-title: theme(colors.black);
  --color-card-button-icon: theme(colors.neutral.600);
  --color-card-button-circle-icon: theme(colors.white);
  --color-card-button-circle-icon-background: theme(colors.black);

  .dark-theme {
    --color-card-button-title: theme(colors.secondary.100);
    --color-card-button-icon: theme(colors.secondary.500);
    --color-card-button-circle-icon: theme(colors.secondary.1000);
    --color-card-button-circle-icon-background: theme(colors.secondary.100);
  }
}
