:root {
  --color-toggle-text: theme(colors.black);
  --color-toggle-icon: theme(colors.neutral.500);
  --color-toggle-border: theme(colors.neutral.200);

  --color-toggle-description: theme(colors.neutral.700);

  .dark-theme {
    --color-toggle-text: theme(colors.secondary.100);
    --color-toggle-icon: theme(colors.secondary.500);
    --color-toggle-border: theme(colors.secondary.1000);

    --color-toggle-description: theme(colors.secondary.200);
  }
}
