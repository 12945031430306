ul {
  &:not(.checklist) {
    @apply list-disc
    pl-8;

    li {
      @apply my-2.5
      pl-0;
      color: var(--color-list-text);
    }
  }

  &.checklist {
    @apply relative
    mx-0
    list-none
    pl-0;

    li {
      @apply before:font-icon
      relative
      mx-0
      mb-4
      pl-9
      before:absolute
      before:left-0
      before:top-[1px]
      before:h-5
      before:w-5
      before:rounded-full
      before:border
      before:text-center
      before:text-[0.625rem]
      before:leading-[1.188rem]
      before:content-['\26'];
      color: var(--color-checklist-text);
      &:before {
        color: var(--color-checklist-icon);
        border-color: var(--color-checklist-border);
        background-color: var(--color-checklist-background);
      }
      &.disabled {
        @apply before:content-[''];
        color: var(--color-checklist-text-disabled);
        &:before {
          border-color: var(--color-checklist-border-disabled);
          background-color: var(--color-checklist-background-disabled);
        }
      }
      &.small {
        @apply mb-3
        pl-6
        before:h-4
        before:w-4
        before:text-[0.475rem]
        before:leading-[0.913rem];
      }
    }

    &-subtitle {
      color: var(--color-checklist-subtitle);
    }
  }
}

ol {
  @apply list-decimal
  pl-8;

  li {
    @apply my-2.5
    pl-0;
    color: var(--color-list-text);
  }
}
