:root {
  --color-like-button-thumb: theme(colors.neutral.700);
  --color-like-button-thumb-hover: theme(colors.black);
  --color-like-button-circle: theme(colors.neutral.300);
  --color-like-button-circle-hover: theme(colors.neutral.400);

  .dark-theme {
    --color-like-button-thumb: theme(colors.secondary.200);
    --color-like-button-thumb-hover: theme(colors.secondary.100);
    --color-like-button-circle: theme(colors.secondary.600);
    --color-like-button-circle-hover: theme(colors.secondary.500);
  }
}
