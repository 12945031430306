:root {
  --color-badge-danger-background: theme(colors.danger.300);
  --color-badge-danger-text: theme(colors.white);

  --color-badge-success-background: theme(colors.primary.400);
  --color-badge-success-text: theme(colors.white);

  --color-badge-warning-background: theme(colors.warning.300);
  --color-badge-warning-text: theme(colors.black);

  .dark-theme {
    --color-badge-danger-text: theme(colors.secondary.1000);
    --color-badge-success-text: theme(colors.secondary.1000);
    --color-badge-warning-text: theme(colors.secondary.1000);
  }
}
