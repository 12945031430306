:root {
  --color-headings: theme(colors.black);
  --color-text: theme(colors.neutral.800);
  --color-text-strong: theme(colors.black);

  /* Content background */
  --color-content-background: theme(colors.neutral.100);
  --color-content-background-dark: theme(colors.black);
  --color-content-background-neutral: theme(colors.white);

  /*Links */
  --color-link: theme(colors.primary.400);
  --color-link-hover: theme(colors.primary.500);
  --color-link-disabled: theme(colors.neutral.500);
  --color-link-pending-border: theme(colors.primary.300);

  /* Info modal icon */
  --color-info-modal-icon: theme(colors.white);
  --color-info-modal-icon-background: theme(colors.neutral.400);

  /* Loading ripple */
  --color-loading-ripple: theme(colors.neutral.600);

  /* Payment model */
  --color-payment-modal-manual-disclaimer: theme(colors.black);

  /* App overlay */
  --color-app-overlay: theme(colors.white / 80%);

  .dark-theme {
    --color-headings: theme(colors.secondary.100);
    --color-text: theme(colors.secondary.200);
    --color-text-strong: theme(colors.secondary.100);

    /* Content background */
    --color-content-background: theme(colors.secondary.1000);
    --color-content-background-dark: theme(colors.black);
    --color-content-background-neutral: theme(colors.secondary.1000);

    /* Links */
    --color-link: theme(colors.primary.400);
    --color-link-hover: theme(colors.primary.500);
    --color-link-disabled: theme(colors.secondary.500);
    --color-link-pending-border: theme(colors.primary.300);

    /* Info modal icon */
    --color-info-modal-icon: theme(colors.secondary.200);
    --color-info-modal-icon-background: theme(colors.secondary.700);

    /* Loading ripple */
    --color-loading-ripple: theme(colors.secondary.400);

    /* Payment model */
    --color-payment-modal-manual-disclaimer: theme(colors.secondary.300);

    /* App overlay */
    --color-app-overlay: theme(colors.secondary.1000 / 80%);
  }

  .premium-theme {
    --color-link: theme(colors.premium.400);
    --color-link-hover: theme(colors.premium.500);
  }
}
