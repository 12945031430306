/* Intercom chat styling tweaks for web */
body {
  /* Intercom launch icon styling to prevent overlap with navbar in portrait mode */
  &.navVisible {
    &-portrait {
      /* Intercom default launch icon */
      .intercom-lightweight-app-launcher {
        @apply bottom-20 #{!important};
        @apply lg:landscape:bottom-5 #{!important};
      }

      /* Intercom when opened chat position fix */
      #intercom-container {
        /* Intercom default launch icon when opened */
        .intercom-dfosxs {
          @apply bottom-20 #{!important};
          @apply lg:landscape:bottom-5 #{!important};
        }
        /* Untill 450px width the chat is modal is opened full screen */
        @media (min-width: 450px) {
          .intercom-messenger-frame {
            @apply bottom-36 #{!important};
            @apply lg:landscape:bottom-20 #{!important};
          }
        }
      }
    }
  }

  &.modal-is-open {
    /* Hide icons when an modal is opened */
    .intercom-lightweight-app-launcher,
    #intercom-container .intercom-dfosxs {
      @apply hidden #{!important};
    }
  }
}
