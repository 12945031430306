:root {
  --color-upload-image-background: theme(colors.neutral.300);
  --color-upload-image-wrapper-background: theme(colors.black / 50%);
  --color-upload-image-wrapper-icon: theme(colors.white);

  --color-upload-title: theme(colors.black);
  --color-upload-subtitle: theme(colors.neutral.700);
  --color-upload-error: theme(colors.danger.400);
  --color-upload-success: theme(colors.success.400);

  --color-upload-list-style-border: theme(colors.neutral.400);

  .dark-theme {
    --color-upload-image-background: theme(colors.secondary.800);

    --color-upload-title: theme(colors.secondary.100);
    --color-upload-subtitle: theme(colors.secondary.500);
    --color-upload-error: theme(colors.danger.400);
    --color-upload-success: theme(colors.success.400);

    --color-upload-list-style-border: theme(colors.secondary.700);
  }
}
