:root {
  --color-input-label: theme(colors.neutral.700);

  --color-input-text: theme(colors.black);
  --color-input-text-placeholder: theme(colors.neutral.500);
  --color-input-text-disabled: theme(colors.neutral.700);

  --color-input-border: theme(colors.neutral.400);
  --color-input-border-focus: theme(colors.black);
  --color-input-border-disabled: theme(colors.neutral.400);
  --color-input-border-error: theme(colors.danger.300);

  --color-input-icon: theme(colors.neutral.500);

  --color-input-button-minus-text: theme(colors.neutral.700);
  --color-input-button-minus-background: theme(colors.neutral.300);
  --color-input-button-plus-text: theme(colors.white);
  --color-input-button-plus-background: theme(colors.black);

  --color-input-progressbar-background: theme(colors.primary.500);

  .dark-theme {
    --color-input-label: theme(colors.secondary.400);

    --color-input-text: theme(colors.secondary.100);
    --color-input-text-placeholder: theme(colors.secondary.600);
    --color-input-text-disabled: theme(colors.secondary.500);

    --color-input-border: theme(colors.secondary.700);
    --color-input-border-focus: theme(colors.secondary.500);
    --color-input-border-disabled: theme(colors.secondary.700);
    --color-input-border-error: theme(colors.danger.300);

    --color-input-icon: theme(colors.secondary.600);

    --color-input-button-minus-text: theme(colors.secondary.200);
    --color-input-button-minus-background: theme(colors.secondary.800);
    --color-input-button-plus-text: theme(colors.secondary.800);
    --color-input-button-plus-background: theme(colors.secondary.200);
  }

  .premium-theme {
    --color-input-progressbar-background: theme(colors.premium.500);
  }
}
