:root {
  --color-boosters-carousel-placeholder: theme(colors.neutral.200);

  --color-boosters-column-label: theme(colors.neutral.600);

  --color-boosters-info-title: theme(colors.black);
  --color-boosters-info-description: theme(colors.neutral.800);

  .dark-theme {
    --color-boosters-carousel-placeholder: theme(colors.secondary.900 / 25%);

    --color-boosters-column-label: theme(colors.secondary.400);

    --color-boosters-info-title: theme(colors.secondary.100);
    --color-boosters-info-description: theme(colors.secondary.200);
  }
}
