ion-popover {
  &.menu-popover {
    --background: none;
    --backdrop-opacity: 0;
    --box-shadow: none;

    --min-width: 18rem;

    &::part(backdrop) {
      background-color: transparent;
    }

    &::part(content) {
      overflow: visible;
      background-color: transparent;
    }
  }
  &.menu-popover-small {
    --min-width: 14rem;
  }

  &.select-popover {
    --background: none;
    --offset-x: 1.5rem;
    --width: 10rem;

    &::part(content) {
      overflow: visible;
      background-color: transparent;
      @apply overflow-hidden
      rounded-2xl;
    }
  }
}

ion-select-popover {
  --background: var(--color-ion-select-popover-background);

  ion-list {
    background: var(--color-ion-select-popover-background) !important;
    padding: 0 !important;
  }

  ion-item.select-interface-option,
  ion-item.item-radio-checked.sc-ion-select-popover-md {
    --border-color: transparent;
    --background: transparent;
    --background-activated: transparent;
    --background-focused: transparent;
    --background-hover: transparent;
    --min-height: 0.5rem;

    &::part(native) {
      @apply bg-transparent;
    }
  }

  ion-radio-group ion-item {
    @apply my-3;

    &:first-child {
      @apply mt-4;
    }
    &:nth-last-of-type(1) {
      @apply mb-4;
    }
  }

  ion-radio {
    @apply text-sm;
    color: var(--color-ion-select-popover-item-label);
    &:hover {
      color: var(--color-ion-select-popover-item-selected-label);
    }
    &.radio-checked {
      @apply after:font-icon
      relative
      font-medium
      after:absolute
      after:right-0
      after:top-1/2
      after:-translate-y-1/2
      after:text-xs
      after:content-['\26'];
      &:after {
        color: var(--color-ion-select-popover-item-selected-icon);
      }
      color: var(--color-ion-select-popover-item-selected-label);
    }
  }
}
