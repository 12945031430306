:root {
  --color-picker-background: theme('colors.white');
  --color-picker-background-rgb: 255, 255, 255;
  --color-picker-border: theme('colors.neutral.300');
  --color-picker-toobar-button: theme('colors.black');
  --color-picker-options: theme(colors.black);

  ion-app.md {
    --color-picker-items-color: theme('colors.neutral.700');
  }

  .dark-theme {
    --color-picker-background: theme('colors.secondary.1000');
    --color-picker-background-rgb: 18, 18, 19;
    --color-picker-border: theme('colors.secondary.700');
    --color-picker-toobar-button: theme('colors.white');
    --color-picker-options: theme(colors.white);
  }
}
