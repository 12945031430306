:root {
  --color-autocomplete-input-background: theme(colors.neutral.300);
  --color-autocomplete-input-color: theme(colors.black);
  --color-autocomplete-input-placeholder-color: theme(colors.neutral.600);
  --color-autocomplete-input-icon-color: theme(colors.black);

  .dark-theme {
    --color-autocomplete-input-background: theme(colors.secondary.700);
    --color-autocomplete-input-color: theme(colors.secondary.100);
    --color-autocomplete-input-placeholder-color: theme(colors.secondary.400);
    --color-autocomplete-input-icon-color: theme(colors.secondary.400);
  }
}
