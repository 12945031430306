:root {
  --color-icon-white: theme(colors.white);
  --color-icon-black: theme(colors.black);
  --color-icon-contrast: theme(colors.neutral.500);
  --color-icon-primary: theme(colors.primary.400);
  --color-icon-success: theme(colors.success.300);
  --color-icon-warning: theme(colors.warning.300);
  --color-icon-danger: theme(colors.danger.300);

  .dark-theme {
    --color-icon-contrast: theme(colors.secondary.400);
  }
}
