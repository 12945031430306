:root {
  /* Primary colors */
  --color-primary-50: 239 248 243; // #EFF8F3
  --color-primary-100: 230 247 237; // #E6F7ED
  --color-primary-200: 175 229 196; // #AFE5C4
  --color-primary-300: 100 192 136; // #64C088
  --color-primary-400: 25 155 101; // #199B65
  --color-primary-500: 11 107 67; // #0B6B43
  --color-primary-900: 20 38 28; // #14261C

  /* Secondary colors */
  --color-secondary-100: 229 232 238; // #E5E8EE
  --color-secondary-200: 191 191 200; // #BFBFC8
  --color-secondary-300: 145 149 155; // #91959B
  --color-secondary-400: 114 118 124; // #72767C
  --color-secondary-500: 93 96 99; // #5D6063
  --color-secondary-600: 58 58 61; // #3A3A3D
  --color-secondary-650: 46 46 51; // #2E2E33
  --color-secondary-700: 35 35 37; // #232325
  --color-secondary-800: 36 36 39; // #242427
  --color-secondary-900: 27 27 28; // #1B1B1C
  --color-secondary-1000: 18 18 19; // #121213

  /* Tertiary colors */
  --color-tertiary-50: 241 247 248; // #F1F7F8
  --color-tertiary-100: 233 243 247; // #E9F3F7
  --color-tertiary-200: 209 232 241; // #D1E8F1
  --color-tertiary-300: 173 211 223; // #ADD3DF
  --color-tertiary-350: 157 188 192; // #9DBCC0
  --color-tertiary-400: 106 174 203; // #6AAECB
  --color-tertiary-500: 89 152 178; // #5998B2
  --color-tertiary-600: 56 67 71; // #384347
  --color-tertiary-900: 35 42 45; // #232A2D
  --color-tertiary-1000: 33 40 41; // #212829

  /* Neutral colors */
  --color-neutral-100: 249 249 249; // #F9F9F9
  --color-neutral-200: 245 245 245; // #F5F5F5
  --color-neutral-300: 236 236 236; // #ECECEC
  --color-neutral-400: 224 224 224; // #E0E0E0
  --color-neutral-500: 190 190 190; // #BEBEBE
  --color-neutral-600: 155 155 155; // #9B9B9B
  --color-neutral-700: 118 118 118; // #767676
  --color-neutral-800: 74 74 74; // #4A4A4A
  --color-neutral-850: 42 42 42; // #2A2A2A
  --color-neutral-900: 11 11 11; // #0B0B0B

  /* Premium colors */
  --color-premium-100: 244 242 239; // #F4F2EF
  --color-premium-200: 229 223 212; // #E5DFD4
  --color-premium-300: 223 206 181; // #DFCEB5
  --color-premium-400: 197 178 146; // #C5B292
  --color-premium-500: 184 149 91; // #B8955B
  --color-premium-600: 129 115 91; // #81735B
  --color-premium-900: 49 43 34; // #312B22

  /* Status colors */
  --color-success-100: 230 247 237; // #E6F7ED
  --color-success-300: 100 192 136; // #64C088
  --color-success-400: 25 155 101; // #199B65
  --color-success-900: 20 38 28; // #14261C

  --color-warning-100: 255 226 199; // #FFE2C7
  --color-warning-300: 255 165 0; // #FFA500
  --color-warning-400: 218 141 0; // #DA8D00
  --color-warning-900: 42 33 17; // #2A2111

  --color-danger-100: 255 199 209; // #FFC7D1
  --color-danger-300: 255 84 115; // #FF5473
  --color-danger-400: 255 67 101; // #FF4365
  --color-danger-900: 42 25 29; // #2A191D
}
