:root {
  --color-invitation-card-background: theme(colors.white);
  --color-invitation-card-title: theme(colors.black);
  --color-invitation-card-subtitle: theme(colors.neutral.700);

  .dark-theme {
    --color-invitation-card-background: theme(colors.secondary.900);
    --color-invitation-card-title: theme(colors.secondary.100);
    --color-invitation-card-subtitle: theme(colors.secondary.200);
  }
}
