:root {
  --color-icon-card-fill: theme(colors.white);
  --color-icon-card-border: theme(colors.white);
  --color-icon-card-title: theme(colors.neutral.700);

  --color-icon-card-fill-enabled: theme(colors.white);
  --color-icon-card-border-enabled: theme(colors.white);
  --color-icon-card-title-enabled: theme(colors.black);

  --color-icon-card-fill-selected: theme(colors.primary.50);
  --color-icon-card-border-hover: theme(colors.primary.400);
  --color-icon-card-border-selected: theme(colors.primary.400);
  --color-icon-card-title-selected: theme(colors.black);

  .dark-theme {
    --color-icon-card-fill: theme(colors.secondary.900);
    --color-icon-card-border: theme(colors.secondary.900);
    --color-icon-card-title: theme(colors.secondary.300);

    --color-icon-card-fill-enabled: theme(colors.secondary.900);
    --color-icon-card-border-enabled: theme(colors.secondary.900);
    --color-icon-card-title-enabled: theme(colors.secondary.100);

    --color-icon-card-fill-selected: theme(colors.primary.900);
    --color-icon-card-border-hover: theme(colors.primary.400);
    --color-icon-card-border-selected: theme(colors.primary.400);
    --color-icon-card-title-selected: theme(colors.secondary.100);
  }

  .premium-theme {
    --color-icon-card-fill-selected: theme(colors.premium.100);
    --color-icon-card-border-hover: theme(colors.premium.400);
    --color-icon-card-border-selected: theme(colors.premium.400);
    --color-icon-card-title-selected: theme(colors.black);

    &.dark-theme {
      --color-icon-card-fill-enabled: theme(colors.secondary.900);
      --color-icon-card-border-enabled: theme(colors.secondary.900);
      --color-icon-card-title-enabled: theme(colors.secondary.100);

      --color-icon-card-fill-selected: theme(colors.premium.900);
      --color-icon-card-border-hover: theme(colors.premium.400);
      --color-icon-card-border-selected: theme(colors.premium.400);
      --color-icon-card-title-selected: theme(colors.secondary.100);
    }
  }
}
