:root {
  --color-modal-background: theme(colors.neutral.100);
  --color-modal-backdrop: theme(colors.black);
  --opacity-modal-backdrop: 0.4;

  .dark-theme {
    --color-modal-background: theme(colors.secondary.1000);
    --color-modal-backdrop: theme(colors.secondary.700);
    --opacity-modal-backdrop: 0.6;
  }
}
