:root {
  --color-account-value: theme(colors.black);
  --color-account-value-label: theme(colors.black);
  --color-account-value-positive: theme(colors.primary.400);

  --color-account-subvalue: theme(colors.neutral.700);
  --color-account-subvalue-label: theme(colors.neutral.700);

  .dark-theme {
    --color-account-value: theme(colors.secondary.100);
    --color-account-value-label: theme(colors.secondary.100);

    --color-account-subvalue: theme(colors.secondary.500);
    --color-account-subvalue-label: theme(colors.secondary.500);
  }
}
