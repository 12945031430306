:root {
  --color-account-withdrawal-item-border: theme(colors.neutral.200);

  --color-account-withdrawal-item-date: theme(colors.neutral.600);
  --color-account-withdrawal-item-value: theme(colors.black);
  --color-account-withdrawal-item-title: theme(colors.black);
  --color-account-withdrawal-item-stornation: theme(colors.neutral.600);
  --color-account-withdrawal-item-cancel: theme(colors.neutral.600);

  --color-account-withdrawal-item-value-light: theme(colors.neutral.600);
  --color-account-withdrawal-item-title-light: theme(colors.neutral.600);

  --color-account-withdrawal-item-state-cancelled: theme(colors.neutral.600);
  --color-account-withdrawal-item-state-paid: theme(colors.neutral.600);
  --color-account-withdrawal-item-state-processing: theme(colors.warning.300);
  --color-account-withdrawal-item-state-payment-planned: theme(colors.primary.400);

  --color-account-withdrawal-amount-helper-message: theme(colors.neutral.600);

  .dark-theme {
    --color-account-withdrawal-item-border: theme(colors.secondary.1000);

    --color-account-withdrawal-item-date: theme(colors.secondary.500);
    --color-account-withdrawal-item-value: theme(colors.secondary.100);
    --color-account-withdrawal-item-title: theme(colors.secondary.100);
    --color-account-withdrawal-item-stornation: theme(colors.secondary.500);
    --color-account-withdrawal-item-cancel: theme(colors.secondary.500);

    --color-account-withdrawal-item-value-light: theme(colors.secondary.500);
    --color-account-withdrawal-item-title-light: theme(colors.secondary.500);

    --color-account-withdrawal-item-state-cancelled: theme(colors.secondary.500);
    --color-account-withdrawal-item-state-paid: theme(colors.secondary.500);
    --color-account-withdrawal-item-state-processing: theme(colors.warning.300);
    --color-account-withdrawal-item-state-payment-planned: theme(colors.primary.400);

    --color-account-withdrawal-amount-helper-message: theme(colors.secondary.500);
  }

  .premium-theme {
    --color-account-withdrawal-item-state: theme(colors.premium.400);
  }
}
