:root {
  --color-button-default-background: theme(colors.black);
  --color-button-default-border: theme(colors.black);
  --color-button-default-text: theme(colors.white);
  --color-button-default-background-hover: theme(colors.neutral.900);
  --color-button-default-border-hover: theme(colors.neutral.900);
  --color-button-default-text-hover: theme(colors.white);
  --color-button-default-background-disabled: theme(colors.transparent);
  --color-button-default-border-disabled: theme(colors.neutral.400);
  --color-button-default-text-disabled: theme(colors.neutral.500);
  --color-button-default-loading-ripple: theme(colors.white);

  --color-button-primary-background: theme(colors.primary.400);
  --color-button-primary-border: theme(colors.primary.400);
  --color-button-primary-text: theme(colors.white);
  --color-button-primary-background-hover: theme(colors.primary.500);
  --color-button-primary-border-hover: theme(colors.primary.500);
  --color-button-primary-text-hover: theme(colors.white);
  --color-button-primary-background-disabled: theme(colors.transparent);
  --color-button-primary-border-disabled: theme(colors.neutral.400);
  --color-button-primary-text-disabled: theme(colors.neutral.500);
  --color-button-primary-loading-ripple: theme(colors.white);

  --color-button-white-background: theme(colors.white);
  --color-button-white-border: theme(colors.white);
  --color-button-white-text: theme(colors.black);
  --color-button-white-background-hover: theme(colors.white);
  --color-button-white-border-hover: theme(colors.white);
  --color-button-white-text-hover: theme(colors.black);
  --color-button-white-background-disabled: theme(colors.transparent);
  --color-button-white-border-disabled: theme(colors.neutral.400);
  --color-button-white-text-disabled: theme(colors.neutral.500);
  --color-button-white-loading-ripple: theme(colors.black);

  --color-button-contrast-background: theme(colors.neutral.300);
  --color-button-contrast-border: theme(colors.neutral.300);
  --color-button-contrast-text: theme(colors.neutral.700);
  --color-button-contrast-background-hover: theme(colors.neutral.400);
  --color-button-contrast-border-hover: theme(colors.neutral.400);
  --color-button-contrast-text-hover: theme(colors.neutral.700);
  --color-button-contrast-background-disabled: theme(colors.transparent);
  --color-button-contrast-border-disabled: theme(colors.neutral.400);
  --color-button-contrast-text-disabled: theme(colors.neutral.500);
  --color-button-contrast-loading-ripple: theme(colors.neutral.700);

  --color-button-transparent-background: theme(colors.transparent);
  --color-button-transparent-border: theme(colors.neutral.300);
  --color-button-transparent-text: theme(colors.neutral.700);
  --color-button-transparent-background-hover: theme(colors.transparent);
  --color-button-transparent-border-hover: theme(colors.neutral.400);
  --color-button-transparent-text-hover: theme(colors.neutral.800);
  --color-button-transparent-background-disabled: theme(colors.transparent);
  --color-button-transparent-border-disabled: theme(colors.neutral.400);
  --color-button-transparent-text-disabled: theme(colors.neutral.500);
  --color-button-transparent-loading-ripple: theme(colors.neutral.700);

  --color-button-danger-background: theme(colors.danger.300);
  --color-button-danger-border: theme(colors.danger.300);
  --color-button-danger-text: theme(colors.white);
  --color-button-danger-background-hover: theme(colors.danger.400);
  --color-button-danger-border-hover: theme(colors.danger.400);
  --color-button-danger-text-hover: theme(colors.white);
  --color-button-danger-background-disabled: theme(colors.transparent);
  --color-button-danger-border-disabled: theme(colors.neutral.400);
  --color-button-danger-text-disabled: theme(colors.neutral.500);
  --color-button-danger-loading-ripple: theme(colors.white);

  --color-button-primary-background: theme(colors.primary.400);
  --color-button-primary-border: theme(colors.primary.400);
  --color-button-primary-text: theme(colors.white);
  --color-button-primary-background-hover: theme(colors.primary.500);
  --color-button-primary-border-hover: theme(colors.primary.500);
  --color-button-primary-text-hover: theme(colors.secondary.200);
  --color-button-primary-background-disabled: theme(colors.transparent);
  --color-button-primary-border-disabled: theme(colors.secondary.600);
  --color-button-primary-text-disabled: theme(colors.secondary.300);
  --color-button-primary-loading-ripple: theme(colors.white);

  .dark-theme {
    --color-button-default-background: theme(colors.secondary.100);
    --color-button-default-border: theme(colors.secondary.100);
    --color-button-default-text: theme(colors.secondary.1000);
    --color-button-default-background-hover: theme(colors.neutral.300);
    --color-button-default-border-hover: theme(colors.neutral.300);
    --color-button-default-text-hover: theme(colors.secondary.1000);
    --color-button-default-background-disabled: theme(colors.transparent);
    --color-button-default-border-disabled: theme(colors.secondary.600);
    --color-button-default-text-disabled: theme(colors.secondary.300);
    --color-button-default-loading-ripple: theme(colors.secondary.1000);

    --color-button-primary-background: theme(colors.primary.400);
    --color-button-primary-border: theme(colors.primary.400);
    --color-button-primary-text: theme(colors.secondary.1000);
    --color-button-primary-background-hover: theme(colors.primary.500);
    --color-button-primary-border-hover: theme(colors.primary.500);
    --color-button-primary-text-hover: theme(colors.secondary.1000);
    --color-button-primary-background-disabled: theme(colors.transparent);
    --color-button-primary-border-disabled: theme(colors.neutral.300);
    --color-button-primary-text-disabled: theme(colors.neutral.500);
    --color-button-primary-loading-ripple: theme(colors.secondary.1000);

    --color-button-contrast-background: theme(colors.secondary.650);
    --color-button-contrast-border: theme(colors.secondary.650);
    --color-button-contrast-text: theme(colors.secondary.100);
    --color-button-contrast-background-hover: theme(colors.secondary.700);
    --color-button-contrast-border-hover: theme(colors.secondary.700);
    --color-button-contrast-text-hover: theme(colors.secondary.100);
    --color-button-contrast-background-disabled: theme(colors.transparent);
    --color-button-contrast-border-disabled: theme(colors.secondary.600);
    --color-button-contrast-text-disabled: theme(colors.secondary.300);
    --color-button-contrast-loading-ripple: theme(colors.secondary.100);

    --color-button-transparent-background: theme(colors.transparent);
    --color-button-transparent-border: theme(colors.secondary.600);
    --color-button-transparent-text: theme(colors.secondary.200);
    --color-button-transparent-background-hover: theme(colors.transparent);
    --color-button-transparent-border-hover: theme(colors.secondary.500);
    --color-button-transparent-text-hover: theme(colors.secondary.200);
    --color-button-transparent-background-disabled: theme(colors.transparent);
    --color-button-transparent-border-disabled: theme(colors.secondary.600);
    --color-button-transparent-text-disabled: theme(colors.secondary.500);
    --color-button-transparent-loading-ripple: theme(colors.secondary.200);

    --color-button-danger-background: theme(colors.danger.300);
    --color-button-danger-border: theme(colors.danger.300);
    --color-button-danger-text: theme(colors.secondary.1000);
    --color-button-danger-background-hover: theme(colors.danger.400);
    --color-button-danger-border-hover: theme(colors.danger.400);
    --color-button-danger-text-hover: theme(colors.secondary.1000);
    --color-button-danger-background-disabled: theme(colors.transparent);
    --color-button-danger-border-disabled: theme(colors.secondary.600);
    --color-button-danger-text-disabled: theme(colors.secondary.300);
    --color-button-danger-loading-ripple: theme(colors.secondary.1000);

    --color-button-primary-background: theme(colors.primary.400);
    --color-button-primary-border: theme(colors.primary.400);
    --color-button-primary-text: theme(colors.white);
    --color-button-primary-background-hover: theme(colors.primary.500);
    --color-button-primary-border-hover: theme(colors.primary.500);
    --color-button-primary-text-hover: theme(colors.secondary.200);
    --color-button-primary-background-disabled: theme(colors.transparent);
    --color-button-primary-border-disabled: theme(colors.secondary.600);
    --color-button-primary-text-disabled: theme(colors.secondary.300);
    --color-button-primary-loading-ripple: theme(colors.white);
  }
}
