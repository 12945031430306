:root {
  --color-progress-bar-background: theme(colors.neutral.400);
  --color-progress-bar-inner-background: theme(colors.primary.400);

  .dark-theme {
    --color-progress-bar-background: theme(colors.secondary.600);
  }

  .premium-theme {
    --color-progress-bar-inner-background: theme(colors.premium.400);
  }
}
