:root {
  --color-selection-modal-option-title: theme(colors.black);
  --color-selection-modal-option-description: theme(colors.neutral.600);
  --color-selection-modal-option-border: theme(colors.neutral.400);

  --color-selection-modal-option-title-danger: theme(colors.danger.300);

  .dark-theme {
    --color-selection-modal-option-title: theme(colors.secondary.100);
    --color-selection-modal-option-description: theme(colors.secondary.200);
    --color-selection-modal-option-border: theme(colors.secondary.900);
  }
}
