:root {
  --color-fund-item-title: theme(colors.black);
  --color-fund-item-value: theme(colors.black);
  --color-fund-item-percentage: theme(colors.neutral.600);
  --color-fund-item-border: theme(colors.neutral.200);

  .dark-theme {
    --color-fund-item-title: theme(colors.secondary.100);
    --color-fund-item-value: theme(colors.secondary.100);
    --color-fund-item-percentage: theme(colors.secondary.500);
    --color-fund-item-border: theme(colors.secondary.1000);
  }
}
