:root {
  --color-ion-toggle-primary-track-background: theme(colors.neutral.400);
  --color-ion-toggle-primary-track-background-checked: theme(colors.primary.400);
  --color-ion-toggle-primary-handle-background: theme(colors.white);
  --color-ion-toggle-primary-handle-background-checked: theme(colors.white);

  .dark-theme {
    --color-ion-toggle-primary-track-background: theme(colors.secondary.600);
    --color-ion-toggle-primary-track-background-checked: theme(colors.primary.400);
    --color-ion-toggle-primary-handle-background: theme(colors.white);
    --color-ion-toggle-primary-handle-background-checked: theme(colors.white);
  }

  .premium-theme {
    --color-ion-toggle-primary-track-background-checked: theme(colors.premium.400);
  }
}
