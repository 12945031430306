:root {
  --account-goal-calculator-min-height: 20rem;
  --account-goal-chart-height: 14.25rem;
  --color-account-goal-state-icon: theme(colors.white);
  --color-account-goal-state-ontrack: theme(colors.primary.400);
  --color-account-goal-state-offtrack: theme(colors.warning.300);
  --color-account-goal-state-learning: theme(colors.tertiary.400);

  --color-account-goal-label: theme(colors.black);
  --color-account-goal-title: theme(colors.black);
  --color-account-goal-description: theme(colors.neutral.700);

  --color-account-goal-hr: theme(colors.neutral.300);

  --color-account-goal-error-message: theme(colors.neutral.700);

  --color-account-goal-footer-border: theme(colors.neutral.200);
  .dark-theme {
    --color-account-goal-label: theme(colors.white);
    --color-account-goal-title: theme(colors.white);
    --color-account-goal-description: theme(colors.secondary.200);

    --color-account-goal-hr: theme(colors.secondary.800);

    --color-account-goal-error-message: theme(colors.secondary.400);

    --color-account-goal-footer-border: theme(colors.secondary.1000);
  }
}
