:root {
  /* Default */
  --color-item-card-title: theme(colors.neutral.600);
  --color-item-card-date: theme(colors.neutral.600);
  --color-item-card-description: theme(colors.neutral.600);

  --color-item-card-background: theme(colors.transparent);
  --color-item-card-border-bottom: theme(colors.neutral.400);

  --color-item-card-before-selected-border-bottom: theme(colors.transparent);

  --color-item-card-unread-indicator: theme(colors.danger.300);

  /* Unread */
  --color-item-card-unread-title: theme(colors.black);
  --color-item-card-unread-date: theme(colors.neutral.600);
  --color-item-card-unread-description: theme(colors.black);

  --color-item-card-unread-background: theme(colors.transparent);
  --color-item-card-unread-border-bottom: theme(colors.neutral.400);

  /* Active */
  --color-item-card-active-title: theme(colors.black);
  --color-item-card-active-date: theme(colors.neutral.600);
  --color-item-card-active-description: theme(colors.black);

  --color-item-card-active-background: theme(colors.transparent);
  --color-item-card-active-border-bottom: theme(colors.neutral.400);

  /* Selected */
  --color-item-card-selected-title: theme(colors.black);
  --color-item-card-selected-date: theme(colors.neutral.600);
  --color-item-card-selected-description: theme(colors.black);

  --color-item-card-selected-background: theme(colors.white);
  --color-item-card-selected-border-bottom: theme(colors.transparent);

  /* Condensed default */
  --color-item-card-condensed-title: theme(colors.neutral.500);
  --color-item-card-condensed-icon: theme(colors.neutral.500);

  --color-item-card-condensed-background: theme(colors.white);

  /* Condensed active */
  --color-item-card-condensed-active-title: theme(colors.black);
  --color-item-card-condensed-active-icon: theme(colors.neutral.500);

  --color-item-card-condensed-active-background: theme(colors.white);

  /* Condensed selected */
  --color-item-card-condensed-selected-title: theme(colors.black);
  --color-item-card-condensed-selected-icon: theme(colors.black);

  --color-item-card-condensed-selected-background: theme(colors.white);

  .dark-theme {
    /* Default */
    --color-item-card-title: theme(colors.secondary.400);
    --color-item-card-date: theme(colors.secondary.500);
    --color-item-card-description: theme(colors.secondary.500);

    --color-item-card-background: theme(colors.transparent);
    --color-item-card-border-bottom: theme(colors.secondary.800);

    --color-item-card-unread-indicator: theme(colors.danger.300);

    /* Unread */
    --color-item-card-unread-title: theme(colors.secondary.100);
    --color-item-card-unread-date: theme(colors.secondary.500);
    --color-item-card-unread-description: theme(colors.secondary.200);

    --color-item-card-unread-background: theme(colors.transparent);
    --color-item-card-unread-border-bottom: theme(colors.secondary.800);

    /* Active */
    --color-item-card-selected-title: theme(colors.secondary.100);
    --color-item-card-active-date: theme(colors.secondary.500);
    --color-item-card-active-description: theme(colors.secondary.200);

    --color-item-card-active-background: theme(colors.transparent);
    --color-item-card-active-border-bottom: theme(colors.secondary.800);

    /* Selected */
    --color-item-card-selected-title: theme(colors.secondary.100);
    --color-item-card-selected-date: theme(colors.secondary.600);
    --color-item-card-selected-description: theme(colors.secondary.200);

    --color-item-card-selected-background: theme(colors.secondary.900);
    --color-item-card-selected-border-bottom: theme(colors.transparent);

    /* Condensed default */
    --color-item-card-condensed-title: theme(colors.secondary.500);
    --color-item-card-condensed-icon: theme(colors.secondary.500);

    --color-item-card-condensed-background: theme(colors.secondary.900);

    /* Condensed active */
    --color-item-card-condensed-active-title: theme(colors.white);
    --color-item-card-condensed-active-icon: theme(colors.secondary.500);

    --color-item-card-condensed-active-background: theme(colors.secondary.900);

    /* Condensed selected */
    --color-item-card-condensed-selected-title: theme(colors.white);
    --color-item-card-condensed-selected-icon: theme(colors.white);

    --color-item-card-condensed-selected-background: theme(colors.secondary.900);
  }
}
