:root {
  --color-carousel-navigation-arrows: theme(colors.neutral.800);

  --color-carousel-pagination-bullet: theme(colors.neutral.400);
  --color-carousel-pagination-bullet-active: theme(colors.primary.400);

  --color-highlights-carousel-pagination-bullet: theme(colors.neutral.400);
  --color-highlights-carousel-pagination-bullet-active: theme(colors.black);

  --color-carousel-fade: theme(colors.neutral.100);

  .dark-theme {
    --color-carousel-navigation-arrows: theme(colors.secondary.200);

    --color-carousel-pagination-bullet: theme(colors.secondary.700);
    --color-carousel-pagination-bullet-active: theme(colors.primary.400);

    --color-carousel-fade: theme(colors.secondary.1000);
  }

  .premium-theme {
    --color-carousel-pagination-bullet-active: theme(colors.premium.400);
  }
}
