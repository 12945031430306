:root {
  --color-intro-content-bottom-description: theme(colors.neutral.800);
  --color-intro-content-bottom-label: theme(colors.neutral.700);

  --color-intro-content-imageElevation-background: theme(colors.white);

  .dark-theme {
    --color-intro-content-bottom-description: theme(colors.secondary.200);
    --color-intro-content-bottom-label: theme(colors.secondary.500);

    --color-intro-content-imageElevation-background: theme(colors.white);
  }
}
