:root {
  --color-magazine-pre-heading: theme(colors.neutral.600);
  --color-magazine-title: theme(colors.black);
  --color-magazine-author: theme(colors.black);
  --color-magazine-author-description: theme(colors.neutral.600);
  --color-magazine-author-description-separator: theme(colors.neutral.400);

  /* article-content */

  /* magazine-intro */
  --color-magazine-intro: theme(colors.black);

  /* magazine-summary */
  --color-magazine-summary-background: theme(colors.neutral.100);

  /* magazine-note */
  --color-magazine-note-background: theme(colors.neutral.100);

  /* magazine-blockquote */
  --color-magazine-blockquote-border: theme(colors.black);
  --color-magazine-blockquote-color: theme(colors.black);

  .dark-theme {
    --color-magazine-pre-heading: theme(colors.secondary.500);
    --color-magazine-title: theme(colors.secondary.100);
    --color-magazine-author: theme(colors.secondary.100);
    --color-magazine-author-description: theme(colors.secondary.500);
    --color-magazine-author-description-separator: theme(colors.secondary.600);

    /* article-content */

    /* magazine-intro */
    --color-magazine-intro: theme(colors.secondary.100);

    /* magazine-summary */
    --color-magazine-summary-background: theme(colors.secondary.900);

    /* magazine-note */
    --color-magazine-note-background: theme(colors.secondary.900);

    /* magazine-blockquote */
    --color-magazine-blockquote-border: theme(colors.secondary.100);
    --color-magazine-blockquote-color: theme(colors.secondary.100);
  }
}
