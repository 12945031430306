:root {
  --color-magazine-card-background: theme(colors.white);
  --color-magazine-card-image-background: theme(colors.neutral.300);
  --color-magazine-card-pre-heading: theme(colors.neutral.600);
  --color-magazine-card-title: theme(colors.black);

  .dark-theme {
    --color-magazine-card-background: theme(colors.secondary.900);
    --color-magazine-card-image-background: theme(colors.secondary.700);
    --color-magazine-card-pre-heading: theme(colors.secondary.500);
    --color-magazine-card-title: theme(colors.secondary.100);
  }
}
