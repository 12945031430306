:root {
  --color-page-header-icon: theme(colors.black);
  --color-page-header-content: theme(colors.black);
  --color-page-header-background-sticky: theme(colors.neutral.100);

  .dark-theme {
    --color-page-header-icon: theme(colors.white);
    --color-page-header-content: theme(colors.white);
    --color-page-header-background-sticky: theme(colors.secondary.1000);
  }
}
