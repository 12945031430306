:root {
  ion-picker-legacy.onyxx-picker,
  ion-picker.onyxx-picker {
    --border-radius: theme(borderRadius[3xl]);
    --border-width: theme(borderWidth.DEFAULT);
    --border-style: solid;
    --background: var(--color-picker-background);
    --background-rgb: var(--color-picker-background-rgb);
    --border-color: var(--color-picker-border);
    --color: var(--color-picker-text);
    --backdrop-opacity: var(--opacity-modal-backdrop);
    color: var(--color-picker-items-color);

    .picker-wrapper {
      border-width: 0;
    }

    .picker-toolbar {
      @apply h-16
      border-none;
    }

    .picker-button {
      @apply text-base_2
      h-16
      pe-8
      ps-4;
      @apply font-medium #{!important};
      color: var(--color-picker-toobar-button);
    }

    .picker-toolbar-cancel {
      .picker-button {
        @apply pe-4
        ps-8;
        @apply font-normal #{!important};
      }
    }

    .picker-opts {
      .picker-opt {
        color: var(--color-picker-options);
      }
    }
  }
}
