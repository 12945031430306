:root {
  --color-user-avatar-inner-border: theme(colors.neutral.600 / 10%);
  --color-user-avatar-outer-border: theme(colors.neutral.600 / 5%);

  --color-user-profile-avatar-icon: theme(colors.neutral.500);
  --color-user-profile-avatar-background: theme(colors.neutral.100);

  --color-user-profile-avatar-uploader-icon-background: theme(colors.black / 50%);
  --color-user-profile-avatar-uploader-icon: theme(colors.white);

  .dark-theme {
    --color-user-avatar-inner-border: theme(colors.secondary.400 / 10%);
    --color-user-avatar-outer-border: theme(colors.secondary.400 / 5%);

    --color-user-profile-avatar-icon: theme(colors.secondary.600);
    --color-user-profile-avatar-background: theme(colors.secondary.900);
  }
}
