:root {
  ion-datetime {
    border-radius: 50px;
    --background: var(--color-picker-background);
    --background-rgb: var(--color-picker-background-rgb);
    --wheel-highlight-background: var(--color-picker-background);
    --title-color: var(--color-picker-text);

    &.show-month-and-year {
      color: var(--color-picker-items-color);
    }

    &.inline-picker {
      @apply mx-auto
      pt-4;
    }
  }
}
