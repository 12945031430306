:root {
  --color-booster-reward: theme(colors.primary.400);

  .dark-theme {
    --color-booster-reward: theme(colors.primary.400);
  }

  .premium-theme {
    --color-booster-reward: theme(colors.premium.400);
  }
}
