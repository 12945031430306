:root {
  --color-account-boosters-text: theme(colors.tertiary.400);
  --color-account-boosters-background: theme(colors.tertiary.100);
  --account-boosters-shadow: theme(boxShadow.tertiary);

  .dark-theme {
    --color-account-boosters-text: theme(colors.tertiary.400);
    --color-account-boosters-background: theme(colors.tertiary.900);
    --account-boosters-shadow: theme(boxShadow.medium);
  }
}
