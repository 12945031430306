:root {
  --color-projection-card-border: theme(colors.white);
  --color-projection-card-border-active: theme(colors.primary.400);

  --color-projection-card-background: theme(colors.white);
  --color-projection-card-background-bottom: theme(colors.neutral.100);

  --color-projection-card-title: theme(colors.primary.400);
  --color-projection-card-subtitle: theme(colors.neutral.800);

  --color-projection-card-values: theme(colors.black);
  --color-projection-card-values-center: theme(colors.neutral.700);

  --color-projection-card-icon: theme(colors.primary.400);
  --color-projection-card-icon-background: theme(colors.white);

  --color-projection-card-bottom-title: theme(colors.black);
  --color-projection-card-bottom-subtitle: theme(colors.black);

  .dark-theme {
    --color-projection-card-border: theme(colors.secondary.900);
    --color-projection-card-border-active: theme(colors.primary.400);

    --color-projection-card-background: theme(colors.secondary.1000);
    --color-projection-card-background-bottom: theme(colors.secondary.900);
    --color-projection-card-title: theme(colors.primary.400);
    --color-projection-card-subtitle: theme(colors.secondary.500);

    --color-projection-card-values: theme(colors.secondary.100);
    --color-projection-card-values-center: theme(colors.secondary.500);

    --color-projection-card-icon: theme(colors.primary.400);
    --color-projection-card-icon-background: theme(colors.secondary.1000);

    --color-projection-card-bottom-title: theme(colors.secondary.100);
    --color-projection-card-bottom-subtitle: theme(colors.secondary.100);
  }

  .premium-theme {
    --color-projection-card-border-active: theme(colors.premium.400);
    --color-projection-card-title: theme(colors.premium.400);
    --color-projection-card-icon: theme(colors.premium.400);
  }
}
