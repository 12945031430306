:root {
  --color-tag-dark-text: theme(colors.white);
  --color-tag-dark-background: theme(colors.black / 50%);

  --color-tag-primary-text: theme(colors.white);
  --color-tag-primary-background: theme(colors.primary.400);

  --color-tag-variantA-text: theme(colors.white);
  --color-tag-variantA-background: theme(colors.tertiary.400);

  --color-tag-variantB-text: theme(colors.white);
  --color-tag-variantB-background: theme(colors.danger.300);

  --color-tag-variantC-text: theme(colors.primary.400);
  --color-tag-variantC-background: theme(colors.primary.100);

  --color-tag-variantD-text: theme(colors.tertiary.400);
  --color-tag-variantD-background: theme(colors.tertiary.100);

  --color-tag-variantE-text: theme(colors.neutral.600);
  --color-tag-variantE-background: theme(colors.neutral.300);

  --color-tag-variantF-text: theme(colors.tertiary.350);
  --color-tag-variantF-background: theme(colors.tertiary.50);

  .dark-theme {
    --color-tag-variantC-text: theme(colors.primary.400);
    --color-tag-variantC-background: theme(colors.primary.900);

    --color-tag-variantD-text: theme(colors.tertiary.400);
    --color-tag-variantD-background: theme(colors.tertiary.900);

    --color-tag-variantE-text: theme(colors.secondary.300);
    --color-tag-variantE-background: theme(colors.secondary.650);

    --color-tag-variantF-text: theme(colors.tertiary.350);
    --color-tag-variantF-background: theme(colors.tertiary.1000);
  }

  .premium-theme {
    --color-tag-primary-text: theme(colors.white);
    --color-tag-primary-background: theme(colors.premium.400);

    --color-tag-variantC-text: theme(colors.premium.400);
    --color-tag-variantC-background: theme(colors.premium.100);

    --color-tag-variantD-text: theme(colors.tertiary.400);
    --color-tag-variantD-background: theme(colors.tertiary.100);

    --color-tag-variantE-text: theme(colors.neutral.600);
    --color-tag-variantE-background: theme(colors.neutral.300);

    &.dark-theme {
      --color-tag-primary-text: theme(colors.premium.900);
      --color-tag-primary-background: theme(colors.premium.400);

      --color-tag-variantC-text: theme(colors.premium.400);
      --color-tag-variantC-background: theme(colors.premium.900);

      --color-tag-variantD-text: theme(colors.tertiary.400);
      --color-tag-variantD-background: theme(colors.tertiary.900);

      --color-tag-variantE-text: theme(colors.secondary.300);
      --color-tag-variantE-background: theme(colors.secondary.650);
    }
  }
}
