:root {
  --color-transaction-detail-indicator-up-background: theme(colors.danger.100 / 50%);
  --color-transaction-detail-indicator-up-icon: theme(colors.danger.300);
  --color-transaction-detail-indicator-down-background: theme(colors.primary.50);
  --color-transaction-detail-indicator-down-icon: theme(colors.primary.400);

  --color-transaction-detail-amount: theme(colors.black);
  --color-transaction-detail-account-name: theme(colors.neutral.600);

  --color-transaction-detail-left: theme(colors.neutral.700);
  --color-transaction-detail-right: theme(colors.black);

  --color-transaction-detail-border-bottom: theme(colors.neutral.400);

  .dark-theme {
    --color-transaction-detail-indicator-up-background: theme(colors.danger.900);
    --color-transaction-detail-indicator-up-icon: theme(colors.danger.300);
    --color-transaction-detail-indicator-down-background: theme(colors.primary.900);
    --color-transaction-detail-indicator-down-icon: theme(colors.primary.400);

    --color-transaction-detail-amount: theme(colors.secondary.100);
    --color-transaction-detail-account-name: theme(colors.secondary.500);

    --color-transaction-detail-left: theme(colors.secondary.200);
    --color-transaction-detail-right: theme(colors.secondary.100);

    --color-transaction-detail-border-bottom: theme(colors.secondary.900);
  }
}
