:root {
  --color-item-details-background: theme(colors.white);

  --color-item-details-date: theme(colors.neutral.600);
  --color-item-details-title: theme(colors.black);
  --color-item-details-salutation: theme(colors.neutral.800);
  --color-item-details-closing: theme(colors.neutral.800);
  --color-item-details-closing-name: theme(colors.neutral.800);

  .dark-theme {
    --color-item-details-background: theme(colors.secondary.900);

    --color-item-details-date: theme(colors.secondary.500);
    --color-item-details-title: theme(colors.secondary.100);
    --color-item-details-salutation: theme(colors.secondary.200);
    --color-item-details-closing: theme(colors.secondary.200);
    --color-item-details-closing-name: theme(colors.secondary.200);
  }
}
