/**
  TODO: create one stylesheet for html-content (see html render component) make sure theming is still possible
*/

.html-content {
  @apply overflow-y-hidden
  text-base
  font-light
  text-inherit;

  h1,
  h2,
  h3,
  h4 {
    @apply font-medium;
    color: var(--color-text-headings);
  }

  strong,
  b {
    @apply font-medium;
    color: var(--color-text-strong);
  }

  .text-black {
    color: var(--color-text-strong);
  }

  .text-contrast {
    color: var(--color-html-content-contrast);
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply my-5
    font-medium;
    color: var(--color-html-content-headings);
  }

  p {
    @apply mb-5
    leading-6
    text-inherit;
  }

  a {
    color: var(--color-link);
    &:hover {
      color: var(--color-link-hover);
    }
    text-decoration: none;

    &.disabled {
      @apply pointer-events-none
      cursor-not-allowed;
      color: var(--color-link-disabled);
    }

    &.pending {
      @apply relative
      inline-flex
      flex-col-reverse
      overflow-hidden;

      @keyframes underline {
        0% {
          left: -50%;
        }
        100% {
          left: 100%;
        }
      }

      &:after {
        @apply absolute
        -left-1/2
        block
        h-px
        w-1/2
        content-[''];
        animation: underline 800ms cubic-bezier(0.65, 0.05, 0.36, 1) infinite;
        background-color: var(--color-link-pending-border);
      }
    }
  }

  small {
    @apply text-xs;
    color: var(--color-html-content-small);
  }

  .intro {
    @apply font-medium;
  }

  ul,
  ol {
    @apply mb-4
    mt-4;
  }

  .text-underline {
    @apply underline;
  }
}
