:root {
  --color-account-header-label: theme(colors.neutral.600);
  --color-account-header-value: theme(colors.black);

  --color-account-header-profit-positive: theme(colors.primary.400);
  --color-account-header-profit-negative: theme(colors.warning.300);
  --color-account-header-profit-negative-alert: theme(colors.danger.300);

  .dark-theme {
    --color-account-header-label: theme(colors.secondary.500);
    --color-account-header-value: theme(colors.white);
  }
}
