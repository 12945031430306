:root {
  --color-discover-item-border: theme(colors.neutral.300);
  --color-discover-item-title: theme(colors.black);
  --color-discover-item-description: theme(colors.neutral.700);
  --color-discover-item-read-more: theme(colors.neutral.600);

  --color-discover-item-description-hover: theme(colors.neutral.900);
  --color-discover-item-read-more-hover: theme(colors.neutral.700);

  .dark-theme {
    --color-discover-item-border: theme(colors.secondary.800);
    --color-discover-item-title: theme(colors.secondary.100);
    --color-discover-item-description: theme(colors.secondary.200);
    --color-discover-item-read-more: theme(colors.secondary.300);

    --color-discover-item-description-hover: theme(colors.secondary.100);
    --color-discover-item-read-more-hover: theme(colors.secondary.200);
  }
}
