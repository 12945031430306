:root {
  --color-progress-circle-color: theme(colors.primary.400);
  --color-progress-circle-background: theme(colors.white);
  --color-progress-circle-text: theme(colors.black);

  .dark-theme {
    --color-progress-circle-color: theme(colors.primary.400);
    --color-progress-circle-background: theme(colors.secondary.900);
    --color-progress-circle-text: theme(colors.secondary.200);
  }

  .premium-theme {
    --color-progress-circle-color: theme(colors.premium.400);
  }
}
