:root {
  --color-accounts-divider: theme(colors.neutral.400);

  /* Add button under list style (mobile) */
  --color-accounts-add-icon: theme(colors.neutral.600);
  --color-accounts-add-icon-background: theme(colors.white);

  /* Empty account state (mobile) */
  --color-accounts-emptyPlaceholder-title: theme(colors.white);
  --color-accounts-emptyPlaceholder-subtitle: theme(colors.white / 65%);
  --color-accounts-emptyPlaceholder-add-icon: theme(colors.neutral.900);
  --color-accounts-emptyPlaceholder-add-icon-background: theme(colors.white);

  /* Empty account state (desktop) */
  --color-accounts-emptyLargePlaceholder-background: theme(colors.neutral.200);
  --color-accounts-emptyLargePlaceholder-title: theme(colors.black);
  --color-accounts-emptyLargePlaceholder-description: theme(colors.neutral.800);

  .dark-theme {
    --color-accounts-divider: theme(colors.secondary.700);

    /* Add button under list style (mobile) */
    --color-accounts-add-icon: theme(colors.secondary.200);
    --color-accounts-add-icon-background: theme(colors.secondary.700);

    /* Empty account state (desktop) */
    --color-accounts-emptyLargePlaceholder-background: theme(colors.secondary.900);
    --color-accounts-emptyLargePlaceholder-title: theme(colors.secondary.100);
    --color-accounts-emptyLargePlaceholder-description: theme(colors.secondary.200);
  }
}
