:root {
  --color-content-card-title: theme(colors.black);
  --color-content-card-description: theme(colors.neutral.800);
  --color-content-card-cta-label: theme(colors.neutral.600);

  .dark-theme {
    --color-content-card-title: theme(colors.secondary.100);
    --color-content-card-description: theme(colors.secondary.200);
    --color-content-card-cta-label: theme(colors.secondary.400);
  }
}
