// ----------------------------------------
/* Defines a property which is affected by the safe area */
@mixin SafeAreaTop($propToModify, $add: 0px, $important: false) {
  $importantStr: '';
  @if ($important) {
    $importantStr: '!important';
  }
  #{$propToModify}: #{$add} #{$importantStr};
  #{$propToModify}: calc(#{$add} + var(--safe-area-inset-top)) #{$importantStr};
}

// ----------------------------------------
/* Defines a property which is affected by the safe area */
@mixin SafeAreaRight($propToModify, $add: 0px, $important: false) {
  $importantStr: '';
  @if ($important) {
    $importantStr: ' !important';
  }
  #{$propToModify}: #{$add} #{$importantStr};
  #{$propToModify}: calc(#{$add} + var(--safe-area-inset-right)) #{$importantStr};
}

// ----------------------------------------
/* Defines a property which is affected by the safe area */
@mixin SafeAreaBottom($propToModify, $add: 0px, $important: false) {
  $importantStr: '';
  @if ($important) {
    $importantStr: ' !important';
  }
  #{$propToModify}: #{$add} #{$importantStr};
  #{$propToModify}: calc(#{$add} + var(--safe-area-inset-bottom)) #{$importantStr};
}

// ----------------------------------------
/* Defines a property which is affected by the safe area */
@mixin SafeAreaLeft($propToModify, $add: 0px, $important: false) {
  $importantStr: '';
  @if ($important) {
    $importantStr: ' !important';
  }
  #{$propToModify}: #{$add} #{$importantStr};
  #{$propToModify}: calc(#{$add} + var(--safe-area-inset-left)) #{$importantStr};
}
