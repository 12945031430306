:root {
  --color-account-status-direct-debit-good: theme(colors.primary.400);
  --color-account-status-direct-debit-bad: theme(colors.warning.300);

  --color-account-status-text: theme(colors.neutral.700);

  .dark-theme {
    --color-account-status-text: theme(colors.secondary.200);
  }
}
