:root {
  --color-advanced-chart-series-line: theme(colors.primary.400);
  --color-advanced-chart-grid-line: theme(colors.neutral.400);

  --color-advanced-chart-labels: theme(colors.neutral.500);
  --color-advanced-chart-labels-active: theme(colors.neutral.600);

  --color-advanced-chart-crosshair: theme(colors.neutral.400);

  --color-advanced-chart-toggle-background: theme(colors.transparent);
  --color-advanced-chart-toggle-text: theme(colors.neutral.600);
  --color-advanced-chart-toggle-background-selected: theme(colors.neutral.300);
  --color-advanced-chart-toggle-text-selected: theme(colors.neutral.700);

  --color-advanced-chart-empty-icon: theme(colors.neutral.500);
  --color-advanced-chart-empty-text: theme(colors.neutral.700);

  .dark-theme {
    --color-advanced-chart-series-line: theme(colors.primary.400);
    --color-advanced-chart-grid-line: theme(colors.secondary.650);

    --color-advanced-chart-labels: theme(colors.secondary.600);
    --color-advanced-chart-labels-active: theme(colors.secondary.500);

    --color-advanced-chart-crosshair: theme(colors.secondary.650);

    --color-advanced-chart-toggle-background: theme(colors.transparent);
    --color-advanced-chart-toggle-text: theme(colors.secondary.500);
    --color-advanced-chart-toggle-background-selected: theme(colors.secondary.650);
    --color-advanced-chart-toggle-text-selected: theme(colors.secondary.300);

    --color-advanced-chart-empty-icon: theme(colors.secondary.500);
    --color-advanced-chart-empty-text: theme(colors.secondary.500);
  }

  .premium-theme {
    --color-advanced-chart-series-line: theme(colors.premium.400);
  }
}
