:root {
  --color-html-content: theme(colors.neutral.800);
  --color-html-content-contrast: theme(colors.neutral.700);

  --color-html-content-headings: theme(colors.black);
  --color-html-content-strong: theme(colors.black);

  --color-html-content-card-background: theme(colors.neutral.100);
  --color-html-content-card-background-contrast: theme(colors.neutral.200);

  --color-html-content-table-header: theme(colors.black);
  --color-html-content-table-cell: theme(colors.neutral.800);
  --color-html-content-table-row-border: theme(colors.neutral.300);

  --color-html-content-bank-title: theme(colors.black);
  --color-html-content-bank-number: theme(colors.neutral.600);

  --color-html-content-small: theme(colors.neutral.500);

  .dark-theme {
    --color-html-content: theme(colors.secondary.200);
    --color-html-content-contrast: theme(colors.secondary.200);

    --color-html-content-headings: theme(colors.secondary.100);
    --color-html-content-strong: theme(colors.secondary.100);

    --color-html-content-card-background: theme(colors.secondary.900);
    --color-html-content-card-background-contrast: theme(colors.secondary.900);

    --color-html-content-table-header: theme(colors.secondary.100);
    --color-html-content-table-cell: theme(colors.secondary.200);
    --color-html-content-table-row-border: theme(colors.secondary.800);

    --color-html-content-bank-title: theme(colors.secondary.100);
    --color-html-content-bank-number: theme(colors.secondary.400);

    --color-html-content-small: theme(colors.secondary.500);
  }
}
