:root {
  --color-toaster-default-background: theme('colors.neutral.900');
  --color-toaster-default-border: theme('colors.neutral.900');
  --color-toaster-default-text: theme('colors.white');
  --color-toaster-default-button: theme('colors.white');

  --color-toaster-success-background: theme('colors.success.100');
  --color-toaster-success-border: theme('colors.success.300');
  --color-toaster-success-text: theme('colors.black');
  --color-toaster-success-button: theme('colors.black');

  --color-toaster-warning-background: theme('colors.warning.100');
  --color-toaster-warning-border: theme('colors.warning.300');
  --color-toaster-warning-text: theme('colors.black');
  --color-toaster-warning-button: theme('colors.black');

  --color-toaster-danger-background: theme('colors.danger.100');
  --color-toaster-danger-border: theme('colors.danger.300');
  --color-toaster-danger-text: theme('colors.black');
  --color-toaster-danger-button: theme('colors.black');

  .dark-theme {
    --color-toaster-default-background: theme('colors.secondary.650');
    --color-toaster-default-border: theme('colors.secondary.300');
    --color-toaster-default-text: theme('colors.white');
    --color-toaster-default-button: theme('colors.white');

    --color-toaster-success-background: theme('colors.success.900');
    --color-toaster-success-border: theme('colors.success.300');
    --color-toaster-success-text: theme('colors.white');
    --color-toaster-success-button: theme('colors.white');

    --color-toaster-warning-background: theme('colors.warning.900');
    --color-toaster-warning-border: theme('colors.warning.300');
    --color-toaster-warning-text: theme('colors.white');
    --color-toaster-warning-button: theme('colors.white');

    --color-toaster-danger-background: theme('colors.danger.900');
    --color-toaster-danger-border: theme('colors.danger.300');
    --color-toaster-danger-text: theme('colors.white');
    --color-toaster-danger-button: theme('colors.white');
  }
}
