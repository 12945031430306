:root {
  --color-header-label: theme(colors.neutral.600);
  --color-header-title: theme(colors.black);
  --color-header-description: theme(colors.neutral.800);
  --color-header-description-variant: theme(colors.neutral.600);

  .dark-theme {
    --color-header-label: theme(colors.secondary.400);
    --color-header-title: theme(colors.secondary.100);
    --color-header-description: theme(colors.secondary.200);
    --color-header-description-variant: theme(colors.secondary.200);
  }
}
