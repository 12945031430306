@font-face {
  font-family: 'semmie-icons';
  src: url('/assets/fonts/semmie-icons.eot');
  src:
    url('/assets/fonts/semmie-icons.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/semmie-icons.woff') format('woff'),
    url('/assets/fonts/semmie-icons.ttf') format('truetype'),
    url('/assets/fonts/semmie-icons.svg#semmie-icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

$font-family-icon: 'semmie-icons';

.semmie-icon {
  font-family: $font-family-icon;
}

[data-icon]:before {
  font-family: $font-family-icon !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: $font-family-icon !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-2fa:before {
  content: '\61';
}
.icon-visible:before {
  content: '\62';
}
.icon-user:before {
  content: '\63';
}
.icon-upload:before {
  content: '\64';
}
.icon-transactions:before {
  content: '\65';
}
.icon-task:before {
  content: '\66';
}
.icon-support:before {
  content: '\67';
}
.icon-settings:before {
  content: '\68';
}
.icon-overview:before {
  content: '\69';
}
.icon-photo:before {
  content: '\6a';
}
.icon-plus:before {
  content: '\6b';
}
.icon-portfolio:before {
  content: '\6c';
}
.icon-qr:before {
  content: '\6d';
}
.icon-questionmark:before {
  content: '\6e';
}
.icon-questionnaire:before {
  content: '\6f';
}
.icon-rate:before {
  content: '\70';
}
.icon-redeem:before {
  content: '\71';
}
.icon-retry:before {
  content: '\72';
}
.icon-search:before {
  content: '\73';
}
.icon-security:before {
  content: '\74';
}
.icon-security2:before {
  content: '\75';
}
.icon-notifications:before {
  content: '\76';
}
.icon-mobile:before {
  content: '\77';
}
.icon-minus:before {
  content: '\78';
}
.icon-magazine:before {
  content: '\79';
}
.icon-locked:before {
  content: '\7a';
}
.icon-location:before {
  content: '\41';
}
.icon-listview:before {
  content: '\42';
}
.icon-listsort:before {
  content: '\43';
}
.icon-linkedaccount:before {
  content: '\44';
}
.icon-legal:before {
  content: '\45';
}
.icon-learn:before {
  content: '\46';
}
.icon-investmentprofile:before {
  content: '\47';
}
.icon-info:before {
  content: '\48';
}
.icon-email:before {
  content: '\49';
}
.icon-exclamation:before {
  content: '\4a';
}
.icon-exclamation-circle:before {
  content: '\4b';
}
.icon-faceid:before {
  content: '\4c';
}
.icon-filter:before {
  content: '\4d';
}
.icon-fingerprint:before {
  content: '\4e';
}
.icon-flag:before {
  content: '\4f';
}
.icon-graph:before {
  content: '\50';
}
.icon-handle:before {
  content: '\51';
}
.icon-heart:before {
  content: '\52';
}
.icon-hide:before {
  content: '\53';
}
.icon-home:before {
  content: '\54';
}
.icon-inbox:before {
  content: '\55';
}
.icon-edit2:before {
  content: '\56';
}
.icon-edit:before {
  content: '\57';
}
.icon-download:before {
  content: '\58';
}
.icon-dot:before {
  content: '\59';
}
.icon-documents:before {
  content: '\5a';
}
.icon-display:before {
  content: '\30';
}
.icon-discover:before {
  content: '\31';
}
.icon-directions:before {
  content: '\32';
}
.icon-delete:before {
  content: '\33';
}
.icon-code:before {
  content: '\34';
}
.icon-close:before {
  content: '\35';
}
.icon-clock:before {
  content: '\36';
}
.icon-circle:before {
  content: '\37';
}
.icon-accountholders:before {
  content: '\38';
}
.icon-archive:before {
  content: '\39';
}
.icon-booster:before {
  content: '\21';
}
.icon-calculator:before {
  content: '\22';
}
.icon-calendar:before {
  content: '\23';
}
.icon-call:before {
  content: '\24';
}
.icon-chat:before {
  content: '\25';
}
.icon-checkmark:before {
  content: '\26';
}
.icon-chevrondown:before {
  content: '\5e';
}
.icon-chevronleft:before {
  content: '\28';
}
.icon-chevronright:before {
  content: '\29';
}
.icon-chevronup:before {
  content: '\2a';
}
.icon-atom:before {
  content: '\40';
}
.icon-hamburger:before {
  content: '\3d';
}
.icon-appicon:before {
  content: '\7e';
}
.icon-copy:before {
  content: '\2b';
}
.icon-diamond:before {
  content: '\2c';
}
.icon-file:before {
  content: '\2d';
}
.icon-globe:before {
  content: '\2e';
}
.icon-language:before {
  content: '\2f';
}
.icon-more:before {
  content: '\3a';
}
.icon-moon:before {
  content: '\3b';
}
.icon-logout:before {
  content: '\3c';
}
.icon-pushnotification:before {
  content: '\3e';
}
.icon-settings2:before {
  content: '\3f';
}
.icon-access:before {
  content: '\7d';
}
.icon-leaves:before {
  content: '\5b';
}
.icon-tree:before {
  content: '\5d';
}
.icon-tree2:before {
  content: '\5f';
}
.icon-finance:before {
  content: '\60';
}
.icon-time:before {
  content: '\7b';
}
.icon-child:before {
  content: '\27';
}
.icon-baby:before {
  content: '\7c';
}
.icon-backspace:before {
  content: '\5c';
}
.icon-building:before {
  content: '\e000';
}
.icon-dividend:before {
  content: '\e001';
}
.icon-currency:before {
  content: '\e002';
}
.icon-sailboat:before {
  content: '\e003';
}
.icon-funds:before {
  content: '\e006';
}
.icon-attributes:before {
  content: '\e004';
}
.icon-kvk:before {
  content: '\e005';
}
.icon-financial:before {
  content: '\e007';
}
.icon-revenue:before {
  content: '\e008';
}
.icon-structure:before {
  content: '\e009';
}
.icon-arrowdown:before {
  content: '\e00a';
}
.icon-arrowup:before {
  content: '\e00b';
}
.icon-like:before {
  content: '\e00c';
}
.icon-dislike:before {
  content: '\e00d';
}
.icon-arrows-up-down:before {
  content: '\e00e';
}
.icon-coins:before {
  content: '\e00f';
}
.icon-euro-circle:before {
  content: '\e010';
}
.icon-legal2:before {
  content: '\e011';
}
.icon-paper-plane:before {
  content: '\e012';
}
.icon-tools:before {
  content: '\e013';
}
.icon-speaker:before {
  content: '\e014';
}
.icon-user-plus:before {
  content: '\e015';
}
.icon-wallet:before {
  content: '\e016';
}
.icon-chat2:before {
  content: '\e017';
}
.icon-claim:before {
  content: '\e018';
}
.icon-comment:before {
  content: '\e019';
}
.icon-export:before {
  content: '\e01a';
}
.icon-note:before {
  content: '\e01b';
}
.icon-snooze:before {
  content: '\e01c';
}
.icon-todo:before {
  content: '\e01d';
}
.icon-printer:before {
  content: '\e01e';
}
.icon-scale:before {
  content: '\e01f';
}
.icon-save:before {
  content: '\e020';
}
.icon-pin:before {
  content: '\e021';
}
.icon-passport:before {
  content: '\e022';
}
.icon-party:before {
  content: '\e023';
}
.icon-paperclip:before {
  content: '\e024';
}
.icon-reply:before {
  content: '\e025';
}
.icon-sync:before {
  content: '\e026';
}
.icon-table:before {
  content: '\e027';
}
.icon-snooze2:before {
  content: '\e028';
}
.icon-leaf:before {
  content: '\e029';
}
.icon-mountain:before {
  content: '\e02a';
}
.icon-scale2:before {
  content: '\e02b';
}
.icon-thunder:before {
  content: '\e02c';
}
.icon-linkedin:before {
  content: '\e02d';
}
.icon-whatsapp:before {
  content: '\e02e';
}
.icon-x:before {
  content: '\e02f';
}
.icon-bold:before {
  content: '\e030';
}
.icon-italic:before {
  content: '\e031';
}
.icon-image:before {
  content: '\e032';
}
.icon-link:before {
  content: '\e033';
}
.icon-list:before {
  content: '\e034';
}
.icon-underlined:before {
  content: '\e035';
}
.icon-chevron-up-down:before {
  content: '\e036';
}
