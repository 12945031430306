:root {
  --color-bank-divider: theme(colors.neutral.200);

  --opacity-bank-image: theme(opacity.50);
  --opacity-bank-image-active: theme(opacity.100);

  --color-bank-person: theme(colors.neutral.600);
  --color-bank-number: theme(colors.neutral.600);
  --color-bank-person-active: theme(colors.black);
  --color-bank-number-active: theme(colors.neutral.700);

  --color-bank-inactive: theme(colors.warning.300);

  .dark-theme {
    --color-bank-divider: theme(colors.secondary.1000);

    --color-bank-person: theme(colors.secondary.500);
    --color-bank-number: theme(colors.secondary.500);
    --color-bank-person-active: theme(colors.secondary.100);
    --color-bank-number-active: theme(colors.secondary.200);
  }
}
