:root {
  --color-autocomplete-item-title: theme(colors.black);
  --color-autocomplete-item-subtitle: theme(colors.neutral.600);
  --color-autocomplete-item-border: theme(colors.neutral.400);

  --color-autocomplete-no-data: theme(colors.neutral.700);
  .dark-theme {
    --color-autocomplete-item-title: theme(colors.secondary.100);
    --color-autocomplete-item-subtitle: theme(colors.secondary.500);
    --color-autocomplete-item-border: theme(colors.secondary.700);

    --color-autocomplete-no-data: theme(colors.secondary.500);
  }
}
