:root {
  --color-account-direct-debit-title: theme(colors.black);
  --color-account-direct-debit-amount: theme(colors.black);
  --color-account-direct-debit-account: theme(colors.neutral.700);
  --color-account-direct-debit-inactive: theme(colors.neutral.700);

  --color-account-direct-debit-status: theme(colors.neutral.600);
  --color-account-direct-debit-status-active: theme(colors.primary.400);

  --color-account-direct-debit-locked-icon: theme(colors.neutral.500);

  --color-account-direct-debit-border: theme(colors.neutral.200);

  .dark-theme {
    --color-account-direct-debit-title: theme(colors.secondary.100);
    --color-account-direct-debit-amount: theme(colors.secondary.100);
    --color-account-direct-debit-account: theme(colors.secondary.400);
    --color-account-direct-debit-inactive: theme(colors.secondary.400);

    --color-account-direct-debit-status: theme(colors.secondary.500);
    --color-account-direct-debit-status-active: theme(colors.primary.400);

    --color-account-direct-debit-locked-icon: theme(colors.secondary.600);

    --color-account-direct-debit-border: theme(colors.secondary.1000);
  }
}
