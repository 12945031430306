:root {
  ion-toggle {
    &.md {
      @apply pb-3
      pt-3;
    }
    &.primary {
      --track-background: var(--color-ion-toggle-primary-track-background);
      --track-background-checked: var(--color-ion-toggle-primary-track-background-checked);

      --handle-background: var(--color-ion-toggle-primary-handle-background);
      --handle-background-checked: var(--color-ion-toggle-primary-handle-background-checked);
    }
  }
}
