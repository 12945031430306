:root {
  --color-goal-chart-grid-line: theme(colors.neutral.400);
  --color-goal-chart-labels: theme(colors.neutral.500);
  --color-goal-chart-labels-active: theme(colors.neutral.600);
  --color-goal-chart-crosshair: theme(colors.neutral.400);

  --color-goal-chart-goal-line: theme(colors.primary.400);
  --color-goal-chart-goal-label: theme(colors.black);

  --color-goal-chart-realistic: theme(colors.primary.400 / 25%);
  --color-goal-chart-unrealistic: theme(colors.primary.400 / 10%);

  .dark-theme {
    --color-goal-chart-grid-line: theme(colors.secondary.650);
    --color-goal-chart-labels: theme(colors.secondary.600);
    --color-goal-chart-labels-active: theme(colors.secondary.500);
    --color-goal-chart-crosshair: theme(colors.secondary.650);

    --color-goal-chart-goal-line: theme(colors.primary.400);
    --color-goal-chart-goal-label: theme(colors.white);
  }

  .premium-theme {
    --color-goal-chart-goal-line: theme(colors.premium.500);

    --color-goal-chart-realistic: theme(colors.premium.500 / 25%);
    --color-goal-chart-unrealistic: theme(colors.premium.400 / 10%);
  }
}
