:root {
  --color-portfolio-progress-background: theme(colors.neutral.100);
  --color-portfolio-progress-progress-background: theme(colors.neutral.200);
  --color-portfolio-progress-content: theme(colors.black);

  .dark-theme {
    --color-portfolio-progress-background: theme(colors.secondary.800);
    --color-portfolio-progress-progress-background: theme(colors.secondary.650);
    --color-portfolio-progress-content: theme(colors.secondary.100);
  }
}
