/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

@tailwind base;

@layer base {
  /* Display types */
  .display {
    @apply text-display;
  }
  .display_2 {
    @apply text-display2;
  }

  /* Headings */
  h1,
  .h1 {
    @apply text-h1;
  }

  h2,
  .h2 {
    @apply text-h2;
  }

  h3,
  .h3 {
    @apply text-h3;
  }

  h4,
  .h4 {
    @apply text-h4;
  }

  h5,
  .h5 {
    @apply text-h5;
  }

  .sub_heading {
    @apply text-sub_heading;
  }

  /* text */
  .base {
    @apply text-base;
  }
  p,
  .base_2 {
    @apply text-base_2;
  }
  .base_3 {
    @apply text-base_3;
  }

  b,
  strong {
    @apply font-medium;
  }

  /* Small texts */
  .small {
    @apply text-small;
  }
  .tiny {
    @apply text-tiny;
  }

  /* lists */
  ol,
  ul {
    li {
      @apply text-base_2;
    }
  }

  a.link {
    color: var(--color-link);
    &:hover {
      color: var(--color-link-hover);
    }
  }
}
@tailwind components;
@tailwind utilities;

@import 'theme/index';

/* Core CSS required for Ionic components to work properly */
@import '@ionic/angular/css/core.css';

/* Basic CSS for apps built with Ionic */
@import '@ionic/angular/css/normalize.css';
@import '@ionic/angular/css/structure.css';
@import '@ionic/angular/css/typography.css';
@import '@ionic/angular/css/display.css';

/* Unset / modify PrimeNG styling. Remove import if PrimeNG is swapped out */
@import 'theme/primeng-reset';

/* Swiper */
@import 'swiper/swiper-bundle.css';

/* Custom theme CSS, DO NOT IMPORT THIS ANYWHERE ELSE, if you need to use mixing just use `@use 'theme/mixins' as *` */
@import 'theme';

/* Add menu height and bottom safe area height to content-container if tabs are visible */
ion-tabs.navVisible {
  &-portrait {
    & semmie-content-container {
      @include SafeAreaBottom(padding-bottom, theme('height.tab-bar'));
    }
  }
  &-landscape {
    & semmie-content-container {
      @apply lg:landscape:pb-0;
      @media (orientation: landscape) and (min-width: theme('screens.lg')) {
        padding-left: theme('width.tab-bar');
      }
    }
  }
}

html,
body {
  @apply font-sans text-black;
}

ion-content {
  --background: var(--color-content-background);

  &.neutral {
    --background: var(--color-content-background-neutral);
  }

  &.dark {
    --background: var(--color-content-background-dark);
  }
}

ion-refresher {
  &.refresher-native {
    @apply z-[1000000];
  }
}

.plt-android {
  ion-refresher {
    &.refresher-native {
      @include SafeAreaTop(top, 1rem);
      @apply fixed;
    }
  }
}

// Drag and drop global styles
.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drop-list-dragging .cdk-drag {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drag-animating {
  transition: transform 300ms cubic-bezier(0, 0, 0.2, 1);
}

.hide-scroll-bar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.keyboard-is-open {
  // DEV NOTE: Although we should not do it, we have nested ion-content tags. This causes the view on Android to shrink too much
  // when the keyboard is open. Having the explicit check for child ion-content tags is a workaround for this issue.
  //
  // One example. The onboarding component has an ion-content and so does the child onboarding components. But when removing the ion-content
  // tag from the onboarding component, the layout in advisor portal breaks.
  //
  // Overridden in `modals.scss` for specific case with selection-modal
  ion-content.md:not(:has(ion-content.md)) {
    --offset-bottom: calc((var(--keyboard-height)) * -1) !important;
  }
}
