:root {
  --color-list-text: theme(colors.neutral.800);

  --color-checklist-text: theme(colors.neutral.800);
  --color-checklist-subtitle: theme(colors.neutral.600);
  --color-checklist-border: theme(colors.primary.400);
  --color-checklist-background: theme(colors.primary.400);
  --color-checklist-icon: theme(colors.white);

  --color-checklist-text-disabled: theme(colors.neutral.600);
  --color-checklist-border-disabled: theme(colors.neutral.400);
  --color-checklist-background-disabled: theme(colors.transparent);

  .dark-theme {
    --color-list-text: theme(colors.secondary.200);

    --color-checklist-text: theme(colors.secondary.200);
    --color-checklist-subtitle: theme(colors.secondary.400);
    --color-checklist-border: theme(colors.primary.400);
    --color-checklist-background: theme(colors.primary.400);
    --color-checklist-icon: theme(colors.secondary.900);

    --color-checklist-text-disabled: theme(colors.secondary.500);
    --color-checklist-border-disabled: theme(colors.secondary.600);
    --color-checklist-background-disabled: theme(colors.transparent);
  }

  .premium-theme {
    --color-checklist-border: theme(colors.premium.400);
    --color-checklist-background: theme(colors.premium.400);
  }
}
