:root {
  --color-account-header-menu-item: theme(colors.neutral.600);
  --color-account-header-menu-item-active: theme(colors.black);

  --account-overview-section-background: linear-gradient(
    180deg,
    theme(colors.white) 0%,
    theme(colors.neutral.100) 10%,
    theme(colors.neutral.100) 100%
  );

  .dark-theme {
    --color-account-header-menu-item: theme(colors.secondary.400);
    --color-account-header-menu-item-active: theme(colors.secondary.100);

    --account-overview-section-background: linear-gradient(
      180deg,
      theme(colors.secondary.900) 0%,
      theme(colors.secondary.1000) 10%,
      theme(colors.secondary.1000) 100%
    );
  }
}
