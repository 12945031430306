:root {
  --color-performance-label: theme(colors.black);
  --color-performance-value: theme(colors.black);

  --color-performance-sub-label: theme(colors.neutral.700);
  --color-performance-sub-value: theme(colors.neutral.700);

  --color-performance-divider: theme(colors.neutral.200);

  .dark-theme {
    --color-performance-label: theme(colors.secondary.100);
    --color-performance-value: theme(colors.secondary.100);

    --color-performance-sub-label: theme(colors.secondary.500);
    --color-performance-sub-value: theme(colors.secondary.500);

    --color-performance-divider: theme(colors.secondary.1000);
  }
}
