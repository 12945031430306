:root {
  --color-value-transfer-item-border: theme(colors.neutral.300);
  --color-value-transfer-label: theme(colors.neutral.600);
  --color-status-created: theme(colors.tertiary.400);
  --color-status-processing: theme(colors.warning.300);
  --color-status-finished: theme(colors.primary.400);
  --color-status-cancelled: theme(colors.danger.400);

  .dark-theme {
    --color-value-transfer-item-border: theme(colors.secondary.1000);
  }
}
