:root {
  --color-code-numeric-symbol: theme(colors.black);
  --color-code-numeric-border: theme(colors.neutral.500);
  --color-code-numeric-border-focused: theme(colors.black);

  --color-code-dot: theme(colors.secondary.500);
  --color-code-dot-settled: theme(colors.black);

  .dark-theme {
    --color-code-numeric-symbol: theme(colors.secondary.100);
    --color-code-numeric-border: theme(colors.secondary.700);
    --color-code-numeric-border-focused: theme(colors.secondary.500);

    --color-code-dot: theme(colors.secondary.500);
    --color-code-dot-settled: theme(colors.secondary.200);
  }
}
