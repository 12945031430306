:root {
  --color-transaction-history-select: theme(colors.black);
  --color-transaction-history-month: theme(colors.neutral.700);
  --color-transaction-history-value: theme(colors.neutral.700);

  .dark-theme {
    --color-transaction-history-select: theme(colors.secondary.100);
    --color-transaction-history-month: theme(colors.secondary.200);
    --color-transaction-history-value: theme(colors.secondary.200);
  }
}
