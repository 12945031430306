:root {
  --font-family: inherit;
}

.p-component {
  font-family: inherit !important;
}

.p-button {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}
