:root {
  --color-account-card-background: theme(colors.neutral.200);
  --color-account-card-title: theme(colors.white);
  --color-account-card-value: theme(colors.white);
  --color-account-card-profit: theme(colors.white / 70%);

  --color-account-card-delete-background: theme(colors.black / 50%);
  --color-account-card-delete-background-hover: theme(colors.danger.300);
  --color-account-card-delete-icon: theme(colors.white);

  --color-new-account-rounded-background: theme(colors.white);
  --color-new-account-rounded-icon: theme(colors.neutral.600);
  --color-new-account-text: theme(colors.neutral.600);

  .dark-theme {
    --color-account-card-background: theme(colors.secondary.900);

    --color-new-account-rounded-background: theme(colors.secondary.700);
    --color-new-account-rounded-icon: theme(colors.secondary.200);
    --color-new-account-text: theme(colors.secondary.400);
  }
}
