:root {
  --color-checkbox-default-label: theme(colors.black);

  --color-checkbox-background: theme(colors.transparent);
  --color-checkbox-border: theme(colors.neutral.400);
  --color-checkbox-icon: theme(colors.transparent);

  --color-checkbox-background-checked: theme(colors.primary.400);
  --color-checkbox-border-checked: theme(colors.primary.400);
  --color-checkbox-icon-checked: theme(colors.white);

  .dark-theme {
    --color-checkbox-default-label: theme(colors.secondary.200);

    --color-checkbox-background: theme(colors.transparent);
    --color-checkbox-border: theme(colors.secondary.600);
    --color-checkbox-icon: theme(colors.transparent);

    --color-checkbox-background-checked: theme(colors.primary.400);
    --color-checkbox-border-checked: theme(colors.primary.400);
    --color-checkbox-icon-checked: theme(colors.secondary.900);
  }

  .premium-theme {
    --color-checkbox-background-checked: theme(colors.premium.400);
    --color-checkbox-border-checked: theme(colors.premium.400);

    &.dark-theme {
      --color-checkbox-icon-checked: theme(colors.secondary.900);
    }
  }
}
