:root {
  --color-tab-bar-background: theme(colors.white);
  --shadow-tab-bar: theme(boxShadow.tabbar);
  --color-ion-tab-button: theme(colors.neutral.500);
  --color-ion-tab-button-selected: theme(colors.black);
  --color-ion-tab-button-focused: theme(colors.neutral.500);

  .dark-theme {
    --color-tab-bar-background: theme(colors.secondary.900);
    --shadow-tab-bar: theme(boxShadow.tabbarDark);
    --color-ion-tab-button: theme(colors.secondary.500);
    --color-ion-tab-button-selected: theme(colors.secondary.100);
    --color-ion-tab-button-focused: theme(colors.secondary.500);
  }
}
