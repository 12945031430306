:root {
  --color-toggle-item-text: theme(colors.neutral.700);
  --color-toggle-item-text-selected: theme(colors.black);

  --color-toggle-item-group-background: theme(colors.neutral.200);
  --color-toggle-item-group-selection-background: theme(colors.white);

  .dark-theme {
    --color-toggle-item-text: theme(colors.secondary.400);
    --color-toggle-item-text-selected: theme(colors.secondary.100);

    --color-toggle-item-group-background: theme(colors.secondary.800);
    --color-toggle-item-group-selection-background: theme(colors.secondary.900);
  }
}
