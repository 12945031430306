:root {
  --color-select-handler: theme(colors.neutral.700);
  --color-select-handler-background: theme(colors.neutral.300);
  --color-select-label: theme(colors.neutral.700);

  --color-ion-select-popover-background: theme(colors.white);

  --color-ion-select-popover-item-label: theme(colors.neutral.700);
  --color-ion-select-popover-item-label-selected: theme(colors.black);
  --color-ion-select-popover-item-selected-icon: theme(colors.primary.400);

  .dark-theme {
    --color-select-handler: theme(colors.secondary.300);
    --color-select-handler-background: theme(colors.secondary.650);
    --color-select-label: theme(colors.secondary.300);

    --color-ion-select-popover-background: theme(colors.secondary.1000);

    --color-ion-select-popover-item-label: theme(colors.secondary.400);
    --color-ion-select-popover-item-selected-label: theme(colors.secondary.100);
    --color-ion-select-popover-item-selected-icon: theme(colors.primary.400);
  }
}
