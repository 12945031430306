:root {
  --color-menu-item-text: theme(colors.black);
  --color-menu-item-icon: theme(colors.neutral.500);
  --color-menu-item-border: theme(colors.neutral.200);

  --color-menu-item-small-label: theme(colors.neutral.700);
  --color-menu-item-content-text: theme(colors.neutral.600);

  --color-menu-item-text-contrast: theme(colors.neutral.700);
  --color-menu-item-icon-contrast: theme(colors.neutral.500);

  --color-menu-item-text-danger: theme(colors.danger.300);
  --color-menu-item-icon-danger: theme(colors.danger.300);

  .dark-theme {
    --color-menu-item-text: theme(colors.secondary.100);
    --color-menu-item-icon: theme(colors.secondary.500);
    --color-menu-item-border: theme(colors.secondary.1000);

    --color-menu-item-small-label: theme(colors.secondary.500);
    --color-menu-item-content-text: theme(colors.secondary.400);

    --color-menu-item-text-contrast: theme(colors.secondary.200);
    --color-menu-item-icon-contrast: theme(colors.secondary.500);
  }
}
