:root {
  --color-radio-button-default-label: theme(colors.neutral.700);
  --color-radio-button-default-label-select: theme(colors.black);

  --color-radio-button-background: theme(colors.transparent);
  --color-radio-button-border: theme(colors.neutral.400);
  --color-radio-button-border-hover: theme(colors.neutral.500);

  --color-radio-button-background-checked: theme(colors.transparent);
  --color-radio-button-border-checked: theme(colors.primary.400);

  /* Radio button item  */
  --color-radio-button-item-title: theme(colors.neutral.500);
  --color-radio-button-item-description: theme(colors.neutral.500);
  --color-radio-button-item-border: theme(colors.neutral.400);

  --color-radio-button-item-title-enabled: theme(colors.black);
  --color-radio-button-item-description-enabled: theme(colors.neutral.700);

  --color-radio-button-item-title-selected: theme(colors.black);
  --color-radio-button-item-description-selected: theme(colors.neutral.700);

  .dark-theme {
    --color-radio-button-background: theme(colors.transparent);
    --color-radio-button-border: theme(colors.secondary.600);
    --color-radio-button-border-hover: theme(colors.secondary.500);

    --color-radio-button-background-checked: theme(colors.transparent);
    --color-radio-button-border-checked: theme(colors.primary.400);

    /* Radio button item  */
    --color-radio-button-item-title: theme(colors.secondary.500);
    --color-radio-button-item-description: theme(colors.secondary.500);
    --color-radio-button-item-border: theme(colors.secondary.800);

    --color-radio-button-item-title-enabled: theme(colors.secondary.100);
    --color-radio-button-item-description-enabled: theme(colors.secondary.200);

    --color-radio-button-item-title-selected: theme(colors.secondary.100);
    --color-radio-button-item-description-selected: theme(colors.secondary.200);
  }

  .premium-theme {
    --color-radio-button-border-checked: theme(colors.premium.400);
  }
}
