:root {
  --color-card-background: theme(colors.white);
  --color-card-background-contrast: theme(colors.neutral.100);
  --color-card-background-contrast-variation: theme(colors.neutral.100);
  --color-card-header-border: theme(colors.neutral.200);

  .dark-theme {
    --color-card-background: theme(colors.secondary.900);
    --color-card-background-contrast: theme(colors.secondary.900);
    --color-card-background-contrast-variation: theme(colors.secondary.1000);
    --color-card-header-border: theme(colors.secondary.1000);
  }
}
