:root {
  --color-tab-header-background: theme(colors.neutral.100);
  --color-tab-button-text: theme(colors.neutral.600);
  --color-tab-button-text-active: theme(colors.black);
  --color-tab-button-border: theme(colors.neutral.400);
  --color-tab-button-border-active: theme(colors.black);

  .dark-theme {
    --color-tab-header-background: theme(colors.secondary.1000);
    --color-tab-button-text: theme(colors.secondary.500);
    --color-tab-button-text-active: theme(colors.secondary.100);
    --color-tab-button-border: theme(colors.secondary.800);
    --color-tab-button-border-active: theme(colors.secondary.500);
  }
}
