:root {
  --color-asset-allocation-stocks: theme(colors.primary.300);
  --color-asset-allocation-bonds: theme(colors.tertiary.400);
  --color-asset-allocation-private-market: theme(colors.neutral.600);
  --color-asset-allocation-cash: theme(colors.tertiary.350);

  --color-asset-allocation-fill: theme(colors.neutral.100);
  --color-asset-allocation-fill-contrast: theme(colors.white);

  --color-asset-allocation-label: theme(colors.neutral.700);
  --color-asset-allocation-percentage: theme(colors.black);

  .dark-theme {
    --color-asset-allocation-fill: theme(colors.secondary.1000);
    --color-asset-allocation-fill-contrast: theme(colors.secondary.900);

    --color-asset-allocation-label: theme(colors.secondary.200);
    --color-asset-allocation-percentage: theme(colors.white);
  }

  .premium-theme {
    --color-asset-allocation-stocks: theme(colors.premium.400);
    --color-asset-allocation-bonds: theme(colors.tertiary.400);
    --color-asset-allocation-private-market: theme(colors.secondary.300);
    --color-asset-allocation-cash: theme(colors.tertiary.350);
  }
}
