:root {
  --file-drag-drop-border-color: theme(colors.neutral.500);
  --file-drag-drop-border-background: transparent;

  --file-drag-drop-over-border-color: theme(colors.primary.400);
  --file-drag-drop-over-border-background: theme(colors.primary.50);
  --file-drag-drop-border-color-disabled: theme(colors.neutral.300);

  --file-drag-drop-icon-color: theme(colors.neutral.500);
  --file-drag-drop-text: theme(colors.neutral.700);
  --file-drag-drop-link: theme(colors.primary.400);

  --file-drag-drop-text-disabled: theme(colors.neutral.500);

  --file-drag-drop-loading: theme(colors.black);

  .dark-theme {
    --file-drag-drop-border-color: theme(colors.secondary.500);
    --file-drag-drop-border-background: transparent;

    --file-drag-drop-over-border-color: theme(colors.primary.400);
    --file-drag-drop-over-border-background: theme(colors.primary.900);
    --file-drag-drop-border-color-disabled: theme(colors.secondary.600);

    --file-drag-drop-icon-color: theme(colors.secondary.400);
    --file-drag-drop-text: theme(colors.secondary.300);
    --file-drag-drop-link: theme(colors.primary.400);

    --file-drag-drop-text-disabled: theme(colors.secondary.500);

    --file-drag-drop-loading: theme(colors.white);
  }
}
