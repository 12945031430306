@font-face {
  font-family: 'VAG Rounded Next';
  src: url('/assets/fonts/VAGRoundedNext-Light.eot');
  src:
    url('/assets/fonts/VAGRoundedNext-Light.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/VAGRoundedNext-Light.woff2') format('woff2'),
    url('/assets/fonts/VAGRoundedNext-Light.woff') format('woff'),
    url('/assets/fonts/VAGRoundedNext-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'VAG Rounded Next';
  src: url('/assets/fonts/VAGRoundedNext-Regular.eot');
  src:
    url('/assets/fonts/VAGRoundedNext-Regular.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/VAGRoundedNext-Regular.woff2') format('woff2'),
    url('/assets/fonts/VAGRoundedNext-Regular.woff') format('woff'),
    url('/assets/fonts/VAGRoundedNext-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'VAG Rounded Next';
  src: url('/assets/fonts/VAGRoundedNext-Medium.eot');
  src:
    url('/assets/fonts/VAGRoundedNext-Medium.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/VAGRoundedNext-Medium.woff2') format('woff2'),
    url('/assets/fonts/VAGRoundedNext-Medium.woff') format('woff'),
    url('/assets/fonts/VAGRoundedNext-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'VAG Rounded Next';
  src: url('/assets/fonts/VAGRoundedNext-SemiBold.eot');
  src:
    url('/assets/fonts/VAGRoundedNext-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/VAGRoundedNext-SemiBold.woff2') format('woff2'),
    url('/assets/fonts/VAGRoundedNext-SemiBold.woff') format('woff'),
    url('/assets/fonts/VAGRoundedNext-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'VAG Rounded Next';
  src: url('/assets/fonts/VAGRoundedBold.eot');
  src:
    url('/assets/fonts/VAGRoundedBold.eot?#iefix') format('embedded-opentype'),
    url('/assets/fonts/VAGRoundedBold.woff') format('woff'),
    url('/assets/fonts/VAGRoundedBold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  text-rendering: optimizeLegibility;
}

@font-face {
  font-family: 'FontAwesome';
  src: url('/assets/fonts/fa-regular-400.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'FontAwesomeFree';
  src: url('/assets/fonts/fontawesome-free.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'semmiefont';
  src: url('/assets/fonts/semmiefont.ttf') format('truetype');
  font-weight: 500;
}
