:root {
  --color-user-menu-name: theme(colors.black);
  --color-user-menu-email: theme(colors.neutral.700);

  --color-user-menu-app-data: theme(colors.neutral.500);

  .dark-theme {
    --color-user-menu-name: theme(colors.white);
    --color-user-menu-email: theme(colors.secondary.500);

    --color-user-menu-app-data: theme(colors.secondary.500);
  }
}
