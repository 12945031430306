:root {
  --color-referral-card-title: theme(colors.black);
  --color-referral-card-subtitle: theme(colors.neutral.500);

  --color-referral-card-reward: theme(colors.primary.400);

  .dark-theme {
    --color-referral-card-title: theme(colors.secondary.100);
    --color-referral-card-subtitle: theme(colors.secondary.500);
  }

  .premium-theme {
    --color-referral-card-reward: theme(colors.premium.400);
  }
}
